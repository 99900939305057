/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router/index';
import store from '../store/store';
import base from './base';
import {
  Toast
} from "vant";
import "vant/lib/toast/style/index";

axios.defaults.withCredentials = false
console.log(axios.defaults.withCredentials)
/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Toast({
    message: msg,
    duration: 1000,
    forbidClick: true
  });
}


/**
 * 跳转登录页
 * 携带当前页面路由，登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    //400:用户发出的请求有错误
    case 400:
      tip('请求错误');
      break;

    case 422:
      tip(other.error)
      break;
      // 401: 未登录状态，跳转登录页

    case 401:
      toLogin();
      break;
      // 403 token过期
      // 清除token并跳转登录页
    case 403:
      tip('登录过期，请重新登录');
      localStorage.removeItem('token');
      store.commit('loginSuccess', null);
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
      // 404请求不存在
    case 404:
      tip('请求的资源不存在');
      break;
    default:
      console.log(other);
  }
}

// 创建axios实例
var instance = axios.create({
  baseURL: `${base.bd}` ,// 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: true, // 异步请求携带cookie
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    //console.log('token:'+window.localStorage.getItem('token'))
    if (window.localStorage.getItem('token')) {
      config.headers.token = window.localStorage.getItem('token')

    } else {

    }
    const token = store.state.token;
    token && (config.headers.token = token);
    return config;
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  // res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
  res => {
    //商户管理登录超时
    if(res.data.message && res.data.message.code==201){
      console.log(123)
      errorHandle(403, res.data);
      return Promise.reject(res.message.message);
    }
    return res;
  },
  // res => {
  //   //对响应数据做些事
  //   if (res.data && !res.data.success) {
  //     Message({
  //       //  饿了么的消息弹窗组件,类似toast
  //       showClose: true,
  //       message: res.data.error.message.message
  //         ? res.data.error.message.message
  //         : res.data.error.message,
  //       type: "error"
  //     });
  //     return Promise.reject(res.data.error.message);
  //   }
  //   return res;
  // },


  // 请求失败
  error => {
    const {
      response
    } = error;
    if (response) {

      errorHandle(response.status, response.data);
      return Promise.reject(response);
    } else {

      if (!window.navigator.onLine) {
        store.commit('changeNetwork', false);
      } else {
        return Promise.reject(error);
      }
    }
  });

export default instance;
