import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import MuseUI from 'muse-ui'
import 'muse-ui/dist/muse-ui.css'
import './assets/font/material-icons.css'
import api from './api/api'
import 'lib-flexible/flexible.js'
import 'vue2-animate/dist/vue2-animate.min.css'
// 在man.js中引入 weixin-jsapi
import wx from "weixin-jsapi";
import Message from 'muse-ui-message'
import './assets/font/iconfont/iconfont.css'
import './assets/font/iconfont/cdziconfont.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';
import { DatetimePicker } from 'vant';
import Userinfo from './components/global/userinfo';
import Adminuserinfo from './components/global/adminuseinfo';
import axios from 'axios'
// import ECharts from 'vue-echarts'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/component/tooltip'
import * as echarts from "echarts"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from "moment"
import BaiduMap from "vue-baidu-map";
Vue.prototype.$echarts = echarts
Vue.use(BaiduMap, {
  ak: "kHuT1n634XyhUTcOU3oPa2ovhKbOK5NN"
});
Vue.prototype.$moment = moment;
Vue.use(ElementUI);
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
axios.defaults.withCredentials = true;
Vue.use(Vant);
Vue.use(Toast);
Vue.use(DatetimePicker)
Toast.setDefaultOptions({ duration: 600 });
Vue.use(Userinfo);
Vue.component('userinfo', Userinfo);
Vue.use(Adminuserinfo);
Vue.component('adminuserinfo', Adminuserinfo);


// 在man.js中注册 weixin-jsapi
Vue.prototype.$wx = wx
Vue.prototype.$api = api // 将api挂载到vue的原型上

Vue.use(Message)
Vue.use(MuseUI)

// Vue.component('chart', ECharts)

Vue.config.productionTip = false

import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';
NutUI.install(Vue);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
