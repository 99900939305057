
<template>

  <div id="app">
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
    </head>
   
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "./assets/sass/app.scss";

</style>
