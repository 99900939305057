import request2 from "@/utils/request2"

// 代理商登录
export function login(query) {
	return request2({
		url: `/login`,
		method: "post",
		params: query,
	})
}
//订单列表
export function getOrderList(query) {
	return request2(
		{
			url: "/sysOrder/getOrderList",
			method: "get",
			params: query,
		}
	)
}
//订单详情
export function getOrderDetails(query) {
	return request2(
		{
			url: "/sysOrder/getOrderData",
			method: "get",
			params: query,
		}
	)
}
//订单详情2
export function getOrderDetails2(query) {
	return request2(
		{
			url: "/sysOrder/v2/order-detail",
			method: "get",
			params: query,
		}
	)
}
//订单状态追踪
export function getOrderTrack(query) {
	return request2(
		{
			url: "/sysOrder/v2/order-trace",
			method: "get",
			params: query,
		}
	)
}
//获取站点列表
export function getPlotList(query) {
	return request2(
		{
			url: "/plot/getPlotList",
			method: "get",
			params: query,
		}
	)
}
//新增站点
export function addPlot(body) {
	return request2(
		{
			url: "/plot/addPlot",
			method: "post",
			data: body,
		}
	)
}

//删除站点
export function deletePlot(query) {
	return request2(
		{
			url: `/plot/delPlot?id=${query.id}`,
			method: "delete",
		}
	)
}
//编辑站点
export function editPlot(body) {
	return request2(
		{
			url: `/plot/editPlot`,
			method: "post",
			data: body
		}
	)
}
//获取广告列表
export function getAdvertList(query) {
	return request2(
		{
			url: `/advert/getAdvertList`,
			method: "get",
			params: query
		}
	)
}
//新增广告
export function addAdvertList(body) {
	return request2(
		{
			url: `/advert/addAdvert`,
			method: "post",
			data: body
		}
	)
}
//删除广告
export function delAdvert(query) {
	return request2(
		{
			url: `/advert/delAdvert`,
			method: "delete",
			params: query
		}
	)
}
//编辑广告
export function editAdvert(body) {
	return request2(
		{
			url: `/advert/edit`,
			method: "put",
			data: body
		}
	)
}
//获取促销列表
export function getPromotionList(query) {
	return request2(
		{
			url: `/promotion/getPromotionList`,
			method: "get",
			params: query
		}
	)
}
//添加促销
export function addPromotion(body) {
	return request2(
		{
			url: `/promotion/addPromotion`,
			method: "post",
			data: body
		}
	)
}
//更新促销
export function upDatePromotion(body) {
	return request2(
		{
			url: `/promotion/updatePromotion`,
			method: "post",
			data: body
		}
	)
}
//删除促销
export function delPromotion(id) {
	return request2(
		{
			url: `/promotion/delPromotion?id=${id}`,
			method: "delete",
		}
	)
}

//新增设备
export function addEquip(body) {
	return request2(
		{
			url: `/chargingpile/addPile`,
			method: "post",
			data: body
		}
	)
}
//编辑设备
export function editPile(body) {
	return request2(
		{
			url: `/chargingpile/editPile`,
			method: "post",
			data: body
		}
	)
}

//获取设备列表
export function getEquipList(query) {
	return request2(
		{
			url: `/chargingpile/getChargingPileList`,
			method: "get",
			params: query
		}
	)
}
//获取菜单列表
export function getMenuList(data) {
	return request2(
		{
			url: `/menu/getMenuList`,
			method: "get",
			params: data
		}
	)
}


//获取用户数据列表
export function getUserList(data) {
	return request2(
		{
			url: `/registerUser/getRegisterUser`,
			method: "get",
			params: data
		}
	)
}
// 获取用户充值记录
export function getUserChongzhi(data) {
	return request2(
		{
			url: `/registerUser/getRechargeRecods`,
			method: "get",
			params: data
		}
	)
}

// 获取卡列表
export function getCardList(data) {
	return request2(
		{
			url: `/sysCard/getCardList`,
			method: "get",
			params: data
		}
	)
}
//查询注册用户下所有的未挂失的卡
export function getCardListByCustomerId(data) {
	return request2(
		{
			url: `/sysCard/getCardListByCustomerId`,
			method: "get",
			params: data
		}
	)
}
// 转账
export function transferAccounts(body) {
	return request2(
		{
			url: `/sysCard/transferAccounts`,
			method: "post",
			params: body
		}
	)
}

// 挂失卡
export function lossCard(query) {
	return request2(
		{
			url: `/sysCard/lossCard`,
			method: "get",
			params: query
		}
	)
}
// 新增卡
export function addCard(body) {
	return request2(
		{
			url: `/card/addCard`,
			method: "post",
			params: body
		}
	)
}
// 编辑卡
export function editCard(body) {
	return request2(
		{
			url: `/card/editCard`,
			method: "post",
			params: body
		}
	)
}
// 卡充值记录
export function getRechargeRecods(query) {
	return request2(
		{
			url: `/sysCard/getRechargeRecods`,
			method: "get",
			params: query
		}
	)
}
// 卡使用记录
export function getUseRecods(query) {
	return request2(
		{
			url: `/sysCard/getUseRecods`,
			method: "get",
			params: query
		}
	)
}
// 用户余额
export function getMyData(query) {
	return request2(
		{
			url: `/my/getMyData`,
			method: "get",
			params: query
		}
	)
}
// 获取用户信息
export function getUserInfo(query) {
	return request2(
		{
			url: `/registerUser/userInfo`,
			method: "get",
			params: query
		}
	)
}
// 获取今日收益
export function getDayProfit(query) {
	return request2(
		{
			url: `/management/getDayProfit`,
			method: "get",
			params: query
		}
	)
}
// 发送验证码
export function sendMsg(query) {
	return request2(
		{
			url: `/my/sendMsg`,
			method: "get",
			params: query
		}
	)
}
// 提现
export function cashWithdrawal(query) {
	return request2(
		{
			url: `/my/cashWithdrawal`,
			method: "get",
			params: query
		}
	)
}

// 获取规则时段
export function getRulePriceList(query) {
	return request2(
		{
			url: "/rule/getRulePriceList",
			method: "get",
			params: query
		}
	)
}
// 获取规则列表
export function getRuleList(data) {
	return request2({
		url: '/sysRule/getRuleList',
		method: 'get',
		params: data
	})
}
// 编辑规则
export function editRule(data) {
	return request2({
		url: '/sysRule/v2/editRule',
		method: 'post',
		data: data
	})
}
// 新增规则
export function addRule(data) {
	return request2({
		url: '/sysRule/v2/addRule',
		method: 'post',
		data: data
	})
}
// 问题反馈列表
export function getFeedBackList(data) {
	return request2({
		url: '/sysFeedback/getFeedBackList',
		method: 'get',
		params: data
	})
}
// 获取端口列表
export function getPortList(data) {
	return request2({
		url: '/port/getPortList',
		method: 'get',
		params: data
	})
}
//设置充电桩规则
export function setPileRule(data) {
	return request2({
		url: '/chargingpile/setPileRule',
		method: 'get',
		params: data
	})
}
// 开启关闭端口
export function switchPort(data) {
	return request2({
		url: '/port/switchPort',
		method: 'post',
		params: data
	})
}
//订单退款
export function refundOrder(data) {
	return request2({
		url: '/sysOrder/refundOrder',
		method: 'get',
		params: data
	})
}
// 复制规则
export function copyRule(data) {
	return request2({
		url: '/sysRule/copyRule',
		method: 'get',
		params: data
	})
}
// 密码修改
export function changePwd(data) {
	return request2({
		url: '/changePwd',
		method: 'post',
		data: data
	})
}
// 登出
export function loginOut(data) {
	return request2({
		url: '/loginOut',
		method: 'post',
		data: data
	})
}
//订单状态跟踪
export function orderTrace(data) {
	return request2({
		url: '/sysOrder/v2/order-trace',
		method: 'get',
		params: data
	})
}
// 问题反馈详情
export function getFeedBack(data) {
	return request2({
		url: '/sysFeedback/getFeedBack',
		method: 'post',
		params: data
	})
}
// 问题处理
export function doFeedBack(data) {
	return request2({
		url: '/sysFeedback/doFeedBack',
		method: 'get',
		params: data
	})
}
// 充电桩收益
export function getSalesDetailByParams(data) {
	return request2({
		url: '/management/getSalesDetailByParams',
		method: 'get',
		params: data
	})
}

export function getInfoByKey(data) {
    return request2({
        url: '/sysConfig/getInfoByKey',
        method: 'get',
        params: data

    })
}

export function queryNewsByNewsConfigKey(data) {
    return request2({
        url: '/sysNews/queryNewsByNewsConfigKey',
        method: 'post',
        params: data

    })
}